import React from "react"

import { DefaultLayout } from "../layouts/Default"
import { SEO } from "../components/SEO"

const StaticPage = ({ title, description, html, children }) => {
  return (
    <DefaultLayout>
      <SEO title={title} description={description} />

      <div className="pt-6 pb-10">
        <div className="container mx-auto px-8 max-w-screen-md">
          <h1 className="text-5xl font-bold leading-tight mt-1 mb-2 text-gray-800">
            {title}
          </h1>
          <p className="block text-gray-500 font-normal text-md mb-1">
            {description}
          </p>

          <div className="prose prose-blue md:prose-lg mt-6">
            <div dangerouslySetInnerHTML={{ __html: html }} />
            {children}
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default StaticPage
